"use strict";

class CustomerPropertyUnitsController {
    constructor($scope, $localStorage, $timeout, $cookies, SSHttpService, SSUtilService, SSAppService, SSAlertService, SSUserService) {

        const self = this;

        self.$scope = $scope;
        self.$storage = $localStorage;
        self.$cookies = $cookies;
        self.$timeout = $timeout;
        self.SSHttpService = SSHttpService;
        self.SSUtilService = SSUtilService;
        self.SSAppService = SSAppService;
        self.SSAlertService = SSAlertService;
        self.SSUserService = SSUserService;

        self.data = SHOWSUITE.data;

        self.PROPERTY_STATUS_LISTED = SHOWSUITE.PROPERTY_STATUS_LISTED;

        self.PROPERTY_TYPE_PRIVATE_CONDO = 1;
        self.PROPERTY_TYPE_EXECUTIVE_CONDO = 2;
        self.PROPERTY_TYPE_LANDED = 3;
        self.PROPERTY_TYPE_APARTMENT = 4;
        self.PROPERTY_TYPE_INTERGATED_DEVELOPMENT = 5;
        self.PROPERTY_TYPE_COMMERCIAL = 6;

        self.UNIT_CATEGORIES_MAP = {
            0: {code: 'XX', name: 'Other', className: 'empty'},
            1: {code: '1BR', name: '1 Bedroom', className: 'bed1'},
            2: {code: '2BR', name: '2 Bedroom', className: 'bed2'},
            3: {code: '3BR', name: '3 Bedroom', className: 'bed3'},
            4: {code: '4BR', name: '4 Bedroom', className: 'bed4'},
            5: {code: '5BR', name: '5 Bedroom', className: 'bed5'},
            6: {code: 'PH', name: 'Penthouse', className: 'ph'},
            7: {code: 'COM', name: 'Commercial', className: 'retail'},
            1000: {code: 'CT', name: 'Corner Terrace', className: 'corner-terrace'},
            2000: {code: 'IT', name: 'Intermediate Terrace', className: 'interlaced-terrace'},
            3000: {code: 'SD', name: 'Semi-Detached', className: 'semi-detached'},
            4000: {code: 'DE', name: 'Detached', className: 'detached'},
            4001: {code: 'SU', name: 'Storage Unit', className: 'retail'},
            4002: {code: 'WH', name: 'Warehouse', className: 'detached'},
        };

        self.UNIT_STATUS_LISTED = SHOWSUITE.UNIT_STATUS_LISTED;
        self.UNIT_STATUS_DRAFT = SHOWSUITE.UNIT_STATUS_DRAFT;
        self.UNIT_STATUS_RESERVED = SHOWSUITE.UNIT_STATUS_RESERVED;
        self.UNIT_STATUS_BOOKED = SHOWSUITE.UNIT_STATUS_BOOKED;
        self.UNIT_STATUS_SOLD = SHOWSUITE.UNIT_STATUS_SOLD;

        self.sortBy = null;
        self.sortByHeading = "Sort by...";
        self.selectedStackId = null;

        self.filterBy = null;
        self.floorplan_filter = "";
        self.floorplan_filter_list = "";

        self.apiOffset = 0;
        self.apiLimit = 10;

        self.FLOORPLAN_ASSET_TYPE_360_IMAGE = SHOWSUITE.FLOORPLAN_ASSET_TYPE_360_IMAGE;

        // PUBLIC PAGES SHOULD NOT HAVE 'ACCESS' CONDITION
        // For example: https://internal.showsuite.com/arena-residences-70/units
        // self.has_access = false;
        // if (self.SSUserService.isDeveloper()) {
        //     self.init();
        //     self.has_access = true;
        // }

        self.init();
    }

    init() {
        const self = this;

        self.language = self.$storage['language'];
        self.getPropertyStacks();
        self.setupFloorPlanFilter();
        self.getPropertyFloorplanTypes();
    }

    initLocaleString(){
        const self = this;
        self.localeStrings = [];
        self.localeStrings['property.' + self.property.id + '.name'] = self.toLocaleDictionary('properties', self.property.id, 'name', self.property.name);
    }

    toLocaleDictionary(table ,id, column_name, original_value){
        const self = this;

        if(!table || !id || !column_name)
            return original_value;

        if(self.$cookies.get('lang') == 'en')
            return original_value;

        if(self.language){
            let result = self.language.filter(function (row) {

                return table+'.'+id+'.'+column_name+'.'+self.$cookies.get('lang') == row.key;
            });
            if(result.length && result[0].value != '')
                return result[0].value;
            else
                return original_value;
        }
    }

    getPropertyStacks() {
        const self = this;

        self.property = self.data.property;
        self.initLocaleString();
        self.stacks = self.data.property_stacks;

        self.checkListViewOnly();
        self.processServerData();
        self.getPropertyUnitTypes();

        self.isEoiAccessible = !!self.SSUserService.getActivePropertyLaunchForEoiCreate(self.property, 'EOI');
    }

    checkListViewOnly() {
        const self = this;
        let stack_view_visibility = self.SSAppService.getPropertySetting(self.property, 'stack_view_visibility');

        if (stack_view_visibility !== null && typeof stack_view_visibility === 'object' && stack_view_visibility.hasOwnProperty("value") && stack_view_visibility.value != null) {
            stack_view_visibility = stack_view_visibility.value;
        }

        if (stack_view_visibility == SHOWSUITE.AVAILABILITY_BROKERS_TEAM) {
            if (!self.SSUserService.isBroker()) {
                self.viewType = 2;
                self.disable_stackview = true;
                return;
            }
            else {
                loading(true);
                self.SSUserService.getBrokerAgencyWithProperty(property).then(function (response) {
                    loading(false);
                    if (response instanceof Error) {
                        self.SSAlertService.parseAndDisplayError(response);
                        return;
                    }
                    if (!(response.data && response.data.properties)) {
                        self.viewType = 2;
                        self.disable_stackview = true;
                        return;
                    }

                    let properties = response.data.properties;

                    $.each(properties, function (index, _property) {
                        if (_property.id === self.property.id) {
                            self.viewType = 1;
                            return;
                        }
                    });

                    if (self.viewType !== 1) {
                        self.viewType = 2;
                        self.disable_stackview = true;
                        return;
                    }
                });
            }
        } else if (stack_view_visibility == SHOWSUITE.AVAILABILITY_BROKERS_ALL) {
            if (self.SSUserService.isBroker()) {
                self.viewType = 1;
            } else {
                self.viewType = 2;
                self.disable_stackview = true;
                return;
            }
        } else if (stack_view_visibility == SHOWSUITE.AVAILABILITY_PUBLIC) {
            self.viewType = 1;
        } else {
            self.viewType = 2;
            self.disable_stackview = true;
        }
    }

    processServerData(all_tower) {
        const self = this;
        self.units_view_master = [];

        if (all_tower) {
            jQuery.each(self.stacks, function (i, stack) {
                stack.floorsData = self.SSUtilService.getFloorWiseUnits(stack.units);
                stack.availableUnits = self.filterAvailableUnits(stack.units);
                self.availableUnits = stack.availableUnits;
                self.loadUnits();
            });
        } else {
            // Select first stack by default
            self.selectedStackId = (self.selectedStackId) ? self.selectedStackId : self.stacks[0].id;

            let selected_stack = self.stacks.find(x => x.id == self.selectedStackId);

            if (selected_stack) {
                selected_stack.floorsData = self.SSUtilService.getFloorWiseUnits(selected_stack.units);
                selected_stack.availableUnits = self.filterAvailableUnits(selected_stack.units);
                self.availableUnits = selected_stack.availableUnits;
                self.loadUnits();
            } 
        }

        self.loadUnitsView();
        
        self.SSAppService.isPageLoaded = true;
    }

    getPropertyUnitTypes() {
        const self = this;

        self.propertyUnitCategories = [];

        jQuery.each(self.stacks, function (i, stack) {
            jQuery.each(stack.units, function (j, unit) {
                let category = null;
                if (unit.category in self.UNIT_CATEGORIES_MAP) {
                    category = self.UNIT_CATEGORIES_MAP[unit.category];
                } else {
                    category = self.UNIT_CATEGORIES_MAP[0];
                }

                if (self.propertyUnitCategories.indexOf(category) == -1) {
                    self.propertyUnitCategories.push(category);
                }
            });
        });
    }

    filterAvailableUnits(units) {
        const self = this;

        let statuses = [self.UNIT_STATUS_LISTED, self.UNIT_STATUS_RESERVED];
        let filtered = [];

        _.each(units, function (unit) {
            if (statuses.indexOf(unit.status) !== -1) {
                let should_add = false;
                switch (self.filterBy) {
                    case "360photo":
                        if (self.checkUnitAssetClass(unit, self.FLOORPLAN_ASSET_TYPE_360_IMAGE)) {
                            should_add = true;
                        }
                        break;
                    case "3Dfloorplan":
                        if (unit.floor_plan_3d != null && unit.floor_plan_3d != "" && unit.floor_plan_3d != undefined) {
                            should_add = true;
                        }
                        break;
                    default:
                        if (self.filterBy == null || self.filterBy == "") {
                            should_add = true;
                        } else {
                            if (self.floorplan_filter == true) {
                                if (unit.status == self.UNIT_STATUS_LISTED) {
                                    let floor_plan = "";
                                    if (unit.floor_plan in self.UNIT_FLOORPLANS_MAP) {
                                        floor_plan = self.UNIT_FLOORPLANS_MAP[unit.floor_plan].className;
                                    }

                                    if (floor_plan == self.filterBy) {
                                        should_add = true;
                                    }
                                }
                            } else {
                                if (unit.status == self.UNIT_STATUS_LISTED) {
                                    let cls = "";
                                    if (unit.category in self.UNIT_CATEGORIES_MAP) {
                                        cls = self.UNIT_CATEGORIES_MAP[unit.category].className;
                                    } else {
                                        cls = self.UNIT_CATEGORIES_MAP[0].className;
                                    }

                                    if (cls == self.filterBy) {
                                        should_add = true;
                                    }
                                }
                            }
                            
                        }                        
                        break;
                }

                if (should_add) {
                    filtered.push(unit);
                }
            }
        });

        if (self.sortBy) {
            filtered = _.sortBy(filtered, function (item) {
                return item[self.sortBy];
            })
        }

        return filtered;
    }

    loadUnits(){
        const self = this;

        if(!self.units_view_master) {
            self.units_view_master = [];
        }
        self.units = [];
        self.units_view = [];

        self.count = self.availableUnits.length;
        self.pages = Math.ceil(self.count / self.apiLimit);
        self.selectedPage = Math.floor(self.apiOffset / self.apiLimit) + 1;
        self.pagesArr = [];

        for (var i = 0; i < self.pages; i++) {
            self.pagesArr.push(i);
        }

        jQuery.each(self.availableUnits, function (i, units) {
            self.units_view_master.push(self.availableUnits[self.apiOffset + i]);
        });
        for(var i = 0; i < self.apiLimit; i++){
            if(self.apiOffset+i < self.count){
                self.units.push(self.availableUnits[self.apiOffset + i]);

            }
        }


        for(var i = 0; i < self.apiLimit; i++){
            self.units_view.push(self.availableUnits[i]);
        }
    }


    loadUnitsView(){
        const self = this;
        self.units_view = [];
        self.count = self.units_view_master.length;
        self.pages = Math.ceil(self.count / self.apiLimit);
        self.selectedPage = Math.floor(self.apiOffset / self.apiLimit) + 1;
        self.pagesArr = [];

        for (var i = 0; i < self.pages; i++) {
            self.pagesArr.push(i);
        }

        for(var i = 0; i < self.apiLimit; i++){
            if(self.apiOffset+i < self.count){
                self.units_view.push(self.units_view_master[self.apiOffset + i]);

            }
        }

    }

    pagesGoBack() {
      const self = this;

      self.selectedPage--;
      self.apiOffset = self.apiOffset - self.apiLimit;
      self.loadUnitsView();
    }

    pagesGoNext() {
      const self = this;

      self.selectedPage++;
      self.apiOffset = self.apiOffset + self.apiLimit;
      self.loadUnitsView();
    }

    gotoPage(pageNum){
      const self = this;

      self.apiOffset = parseInt(pageNum) * self.apiLimit;
      self.loadUnitsView();
    }

    sortData(sortBy) {
        const self = this;

        self.sortBy = sortBy;

        switch (self.sortBy) {
            case "unit_no":
                self.sortByHeading = "#UNIT NO";
                break;
            case "house_no":
                self.sortByHeading = "HOUSE NO";
                break;
            case "name":
                self.sortByHeading = "TYPE";
                break;
            case "price":
                self.sortByHeading = "$PRICE";
                break;
            default:
                self.sortByHeading = "Sort by...";
                break;
        }

        self.processServerData();
    }

    getUnitCellClass(unit) {
        const self = this;

        let cls = '';

        if (!unit.category) {
            cls = 'empty';
        } else if (unit.status == self.UNIT_STATUS_LISTED) {
            if (unit.category in self.UNIT_CATEGORIES_MAP) {
                cls = self.UNIT_CATEGORIES_MAP[unit.category].className;
            } else {
                cls = self.UNIT_CATEGORIES_MAP[0].className;
            }
        } else if (unit.status == self.UNIT_STATUS_SOLD) {
            cls = 'sold';
        } else if (unit.status == self.UNIT_STATUS_DRAFT) {
            cls = 'draft';
        } else {
            cls = 'reserved';
        }

        return cls;
    }

    viewUnit(unit) {
        const self = this;

        window.location.href = '/' + self.data.property.named_id + '/' + unit.named_id;
    }

    filterViewStack(className) {
        const self = this;

        self.selected_class_name = className;
        var buttons = $( "div.scroll" ).find( "button" );

        //Make all visible
        var invisible_buttons = $( "div.scroll" ).find( "button.greyout" );
        jQuery.each(invisible_buttons, function (i, button) {
            $(button).removeClass("greyout");
        });
        
        //Make all invisible other than className
        if(className) {
            jQuery.each(buttons, function (i, button) {
                if(!$(button).hasClass(className)) {
                    $(button).addClass("greyout");
                }
            });
        }
    }

    get360photoClass(unit) {
        const self = this;

        if (self.checkUnitAssetClass(unit, self.FLOORPLAN_ASSET_TYPE_360_IMAGE)) {
            return '360photo';
        }
    }

    get3DFloorPlanClass(unit) {
        if (unit.floor_plan_3d != null && unit.floor_plan_3d != "" && unit.floor_plan_3d != undefined) {
            return '3Dfloorplan';
        }
    }

    checkUnitAssetClass(unit, floorPlanType) {
        const self = this;

        if (unit.hasOwnProperty("unit_assets") && unit.unit_assets.length > 0 && unit.unit_assets.find(x => x.type == floorPlanType)) {
            return true;
        }

        return false;
    }

    filterListView(filterBy, floorplan_filter) {
        const self = this;
        self.floorplan_filter = floorplan_filter || false;

        self.filterBy = filterBy;
        self.selected_class_name = filterBy;

        self.gotoPage(0);
        self.processServerData();
    }

    getFloorPlanClass(unit) {
        const self = this;

        let cls = '';

        if (unit.status == self.UNIT_STATUS_LISTED) {
            if (unit.floor_plan in self.UNIT_FLOORPLANS_MAP) {
                cls = self.UNIT_FLOORPLANS_MAP[unit.floor_plan].className;
            }
        }

        return cls;
    }

    setupFloorPlanFilter() {
        const self = this;

        self.UNIT_FLOORPLANS_MAP = {};

        $.each(self.data.floorplan_type, function(index, floorplan_type) {
            if (!self.UNIT_FLOORPLANS_MAP[floorplan_type.name]) {
                self.UNIT_FLOORPLANS_MAP[floorplan_type.name] = {
                    code: floorplan_type.code,
                    name: floorplan_type.name,
                    className: floorplan_type.id
                };
            }            
        });
    }

    getPropertyFloorplanTypes() {
        const self = this;

        self.propertyUnitFloorplans = [];

        $.each(self.stacks, function (i, stack) {
            $.each(stack.units, function (j, unit) {
                let floor_plan = null;
                if (unit.floor_plan in self.UNIT_FLOORPLANS_MAP) {
                    floor_plan = self.UNIT_FLOORPLANS_MAP[unit.floor_plan];
                }

                if (self.propertyUnitFloorplans.indexOf(floor_plan) == -1) {
                    self.propertyUnitFloorplans.push(floor_plan);
                }
            });
        });

        self.$timeout(function () {
            $("select.stack-select#floorplan_stack").niceSelect('update');
            $("select.stack-select#floorplan_list").niceSelect('update');
        });
    }

    trackStackviewAnalytics() {
        const self = this;

        //Add to Firebase Analytics Event
        firebase.analytics().logEvent('VW_UNIT_STACKVW_CLICKS', {'event_category': 'View Unit', 'property': self.property.name});    

        self.viewType = 1;
    }

    trackListviewAnalytics() {
        const self = this;

        //Add to Firebase Analytics Event
        firebase.analytics().logEvent('VW_UNIT_LISTVW_CLICKS', {'event_category': 'View Unit', 'property': self.property.name});    

        self.viewType = 2;
    }

}

CustomerPropertyUnitsController.$inject = ['$scope', '$localStorage', '$timeout', '$cookies','SSHttpService', 'SSUtilService', 'SSAppService', 'SSAlertService', 'SSUserService'];
app.controller('CustomerPropertyUnitsController', CustomerPropertyUnitsController);
