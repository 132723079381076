"use strict";

class DeveloperSalesUnitsController {
    constructor($scope, $localStorage, $timeout, SSHttpService, SSUtilService, SSConfirmationService, SSAppService, SSAlertService, SSUserService, OTPService, SSConfigService, SSAnalyticsService, SSStackviewService) {

        const self = this;

        self.$scope = $scope;
        self.$localStorage = $localStorage;
        self.$timeout = $timeout;
        self.SSHttpService = SSHttpService;
        self.SSUtilService = SSUtilService;
        self.SSConfirmationService = SSConfirmationService;
        self.SSAppService = SSAppService;
        self.SSAlertService = SSAlertService;
        self.SSUserService = SSUserService;
        self.OTPService = OTPService;
        self.SSConfigService = SSConfigService;
        self.SSAnalyticsService = SSAnalyticsService;
        self.SSStackviewService = SSStackviewService;

        self.data = SHOWSUITE.data;

        self.PROPERTY_TYPE_PRIVATE_CONDO = SHOWSUITE.PROPERTY_TYPE_PRIVATE_CONDO;
        self.PROPERTY_TYPE_EXECUTIVE_CONDO = SHOWSUITE.PROPERTY_TYPE_EXECUTIVE_CONDO;
        self.PROPERTY_TYPE_LANDED = SHOWSUITE.PROPERTY_TYPE_LANDED;
        self.PROPERTY_TYPE_APARTMENT = SHOWSUITE.PROPERTY_TYPE_APARTMENT;
        self.PROPERTY_TYPE_INTERGATED_DEVELOPMENT = SHOWSUITE.PROPERTY_TYPE_INTERGATED_DEVELOPMENT;
        self.PROPERTY_TYPE_COMMERCIAL = SHOWSUITE.PROPERTY_TYPE_COMMERCIAL;

        self.UNIT_CATEGORIES_MAP = {
            0: {code: 'XX', name: 'Other', className: 'empty'},
            1: {code: '1BR', name: '1 Bedroom', className: 'bed1'},
            2: {code: '2BR', name: '2 Bedroom', className: 'bed2'},
            3: {code: '3BR', name: '3 Bedroom', className: 'bed3'},
            4: {code: '4BR', name: '4 Bedroom', className: 'bed4'},
            5: {code: '5BR', name: '5 Bedroom', className: 'bed5'},
            6: {code: 'PH', name: 'Penthouse', className: 'ph'},
            7: {code: 'COM', name: 'Commercial', className: 'retail'},
            1000: {code: 'CT', name: 'Corner Terrace', className: 'corner-terrace'},
            2000: {code: 'IT', name: 'Intermediate Terrace', className: 'interlaced-terrace'},
            3000: {code: 'SD', name: 'Semi-Detached', className: 'semi-detached'},
            4000: {code: 'DE', name: 'Detached', className: 'detached'},
            4001: {code: 'SU', name: 'Storage Unit', className: 'retail'},
            4002: {code: 'WH', name: 'Warehouse', className: 'detached'},
        };

        self.PROPERTY_STATUS_LISTED = SHOWSUITE.PROPERTY_STATUS_LISTED;
        self.PROPERTY_STATUS_ARCHIVED = SHOWSUITE.PROPERTY_STATUS_ARCHIVED;

        self.UNIT_STATUS_LISTED = SHOWSUITE.UNIT_STATUS_LISTED;
        self.UNIT_STATUS_DRAFT = SHOWSUITE.UNIT_STATUS_DRAFT;
        self.UNIT_STATUS_RESERVED = SHOWSUITE.UNIT_STATUS_RESERVED;
        self.UNIT_STATUS_BOOKED = SHOWSUITE.UNIT_STATUS_BOOKED;
        self.UNIT_STATUS_SOLD = SHOWSUITE.UNIT_STATUS_SOLD;
        self.UNIT_STATUS_SPECIAL_RESERVED = SHOWSUITE.UNIT_STATUS_SPECIAL_RESERVED;

        self.SALE_STATUS_DRAFT          =  SHOWSUITE.SALE_STATUS_DRAFT;
        self.SALE_STATUS_PENDING        =  SHOWSUITE.SALE_STATUS_PENDING;
        self.SALE_STATUS_SUBMITTED      =  SHOWSUITE.SALE_STATUS_SUBMITTED;
        self.SALE_STATUS_VERIFIED       =  SHOWSUITE.SALE_STATUS_VERIFIED;
        self.SALE_STATUS_APPROVED       =  SHOWSUITE.SALE_STATUS_APPROVED;
        self.SALE_STATUS_REJECTED       =  SHOWSUITE.SALE_STATUS_REJECTED;
        self.SALE_STATUS_OTP            =  SHOWSUITE.SALE_STATUS_OTP;
        self.SALE_STATUS_CHEQUE_ENTERED =  SHOWSUITE.SALE_STATUS_CHEQUE_ENTERED;
        self.SALE_STATUS_SPA_ENTERED    =  SHOWSUITE.SALE_STATUS_SPA_ENTERED;
        self.SALE_STATUS_EXTENDED       =  SHOWSUITE.SALE_STATUS_EXTENDED;
        self.SALE_STATUS_EXERCISED      =  SHOWSUITE.SALE_STATUS_EXERCISED;
        self.SALE_STATUS_EXPIRED        =  SHOWSUITE.SALE_STATUS_EXPIRED;
        self.SALE_STATUS_ABORTED        =  SHOWSUITE.SALE_STATUS_ABORTED;
        self.SALE_STATUS_INVALID        =  SHOWSUITE.SALE_STATUS_INVALID;
        self.SALE_STATUS_DONE           =  SHOWSUITE.SALE_STATUS_DONE;
        self.SALE_STATUS_DELETED        =  SHOWSUITE.SALE_STATUS_DELETED;

        self.FLOORPLAN_ASSET_TYPE_360_IMAGE = SHOWSUITE.FLOORPLAN_ASSET_TYPE_360_IMAGE;

        self.PAYMENT_PLAN_TYPE_ALL = SHOWSUITE.PAYMENT_PLAN_TYPE_ALL;

        self.PAYMENT_PLAN_TYPE_PVT = SHOWSUITE.PAYMENT_PLAN_TYPE_PVT;

        self.PAYMENT_TYPE_00_STANDARD = SHOWSUITE.PAYMENT_TYPE_00_STANDARD;
        self.PAYMENT_TYPE_01_BOOKING_FEE = SHOWSUITE.PAYMENT_TYPE_01_BOOKING_FEE;
        self.PAYMENT_TYPE_02_DEPOSIT = SHOWSUITE.PAYMENT_TYPE_02_DEPOSIT;
        self.PAYMENT_TYPE_03_FOUNDATION = SHOWSUITE.PAYMENT_TYPE_03_FOUNDATION;
        self.PAYMENT_TYPE_04_CONCRETE_FRAMEWORK = SHOWSUITE.PAYMENT_TYPE_04_CONCRETE_FRAMEWORK;
        self.PAYMENT_TYPE_05_BRICK_WALLS = SHOWSUITE.PAYMENT_TYPE_05_BRICK_WALLS;
        self.PAYMENT_TYPE_06_CEILING = SHOWSUITE.PAYMENT_TYPE_06_CEILING;
        self.PAYMENT_TYPE_07_INTERNAL_WORKS_1 = SHOWSUITE.PAYMENT_TYPE_07_INTERNAL_WORKS_1;
        self.PAYMENT_TYPE_08_INTERNAL_WORKS_2 = SHOWSUITE.PAYMENT_TYPE_08_INTERNAL_WORKS_2;
        self.PAYMENT_TYPE_09_EXTERNAL_WORKS = SHOWSUITE.PAYMENT_TYPE_09_EXTERNAL_WORKS;
        self.PAYMENT_TYPE_10_NOTICE_OF_VACANT_POSSESSION = SHOWSUITE.PAYMENT_TYPE_10_NOTICE_OF_VACANT_POSSESSION;
        self.PAYMENT_TYPE_11_CSC = SHOWSUITE.PAYMENT_TYPE_11_CSC;
        self.PAYMENT_TYPE_13_COMPLETION = SHOWSUITE.PAYMENT_TYPE_13_COMPLETION;
        self.PAYMENT_TYPE_20_REFUND = SHOWSUITE.PAYMENT_TYPE_20_REFUND;

        self.sortBy = null;
        self.sortByHeading = "Sort by...";
        self.developer_payment_plans = [];
        self.const_dict_rule_names = SSConfigService.getPaymentPlanClauseRuleStartFromTypes();

        self.apiOffset = 0;
        self.apiLimit = 10;

        self.unit_allocation_filter_status = 20;

        self.payment_type_setting = {
            value: self.PAYMENT_PLAN_TYPE_ALL
        };

        self.display_payment_plan_obj = null;
        self.edit_percentage = false;
        self.changed_percentage = false;

        self.PAYMENT_TYPES = self.getAvailablePayments();

        self.SALE_STATUS_PENDING = SHOWSUITE.SALE_STATUS_PENDING;
        self.SALE_STATUS_SUBMITTED = SHOWSUITE.SALE_STATUS_SUBMITTED;
        self.SALE_STATUS_VERIFIED = SHOWSUITE.SALE_STATUS_VERIFIED;
        self.SALE_STATUS_APPROVED = SHOWSUITE.SALE_STATUS_APPROVED;
        self.SALE_STATUS_REJECTED = SHOWSUITE.SALE_STATUS_REJECTED;
        self.SALE_STATUS_OTP = SHOWSUITE.SALE_STATUS_OTP;

        self.SALE_STATUS_DRAFT = SHOWSUITE.SALE_STATUS_DRAFT;
        self.SALE_STATUS_CHEQUE_ENTERED = SHOWSUITE.SALE_STATUS_CHEQUE_ENTERED;
        self.SALE_STATUS_SPA_ENTERED = SHOWSUITE.SALE_STATUS_SPA_ENTERED;
        self.SALE_STATUS_EXTENDED = SHOWSUITE.SALE_STATUS_EXTENDED;
        self.SALE_STATUS_EXERCISED = SHOWSUITE.SALE_STATUS_EXERCISED;
        self.SALE_STATUS_EXPIRED = SHOWSUITE.SALE_STATUS_EXPIRED;
        self.SALE_STATUS_ABORTED = SHOWSUITE.SALE_STATUS_ABORTED;
        self.SALE_STATUS_INVALID = SHOWSUITE.SALE_STATUS_INVALID;
        self.SALE_STATUS_DONE = SHOWSUITE.SALE_STATUS_DONE;
        self.SALE_STATUS_DELETED = SHOWSUITE.SALE_STATUS_DELETED;

        self.AUTO_REFRESH_TIME = SHOWSUITE.AUTO_REFRESH_TIME;

        if (self.SSUserService.isDeveloper()) {
            self.init();
        }
    }

    init() {
        const self = this;

        // Trigger View Event for Sales Live Stack View Page
        self.SSAnalyticsService.saveAnalyticEvent({
                event: self.SSAnalyticsService.ANALYTICS_EVENT_VIEWS_DMS_LIVE_STACK_VIEW,
                user_id: self.SSUtilService.getFromLocalStorage('user').id
            }
        );

        self.units = null;
        self.stackViewType = 1;
        self.selectedStackId = null;

        self.stacks = [];
        self.units_view = [];
        self.propertyUnitCategories = [];

        /** WEB-2860 Stack View: Need to add more details in each cell - START **/
        self.stackviewSettings = { // Init stackview_settings defaults
            show_floor_pan: false
        }
        if( self.SSStackviewService.getStackViewSettings() ){
            self.stackviewSettings = self.SSStackviewService.getStackViewSettings(self.stackviewSettings);
        }
        /** WEB-2860 Stack View: Need to add more details in each cell - END **/

        self.initSaleStatuses();

        if (self.data.hasOwnProperty('property')) {
            self.getProperty(self.data.property.named_id);
        } else {
            self.getPropertyList();
        }
        self.getConfig();
        self.getDeveloperPaymentPlans();
    }

    initSaleStatuses(){
        const self = this;

        self.sale_statuses = [
            {
                id: self.SALE_STATUS_PENDING,
                name: "Pending with buyer signing"
            },
            {
                id: self.SALE_STATUS_SUBMITTED,
                name: "Submitted to Team IC for Verification"
            },
            {
                id: self.SALE_STATUS_VERIFIED,
                name: "Verified by Team IC"
            },
            {
                id: self.SALE_STATUS_APPROVED,
                name: "Approved by Developer"
            },
            {
                id: self.SALE_STATUS_REJECTED,
                name: "Rejected by Developer or Team IC"
            },
            {
                id: self.SALE_STATUS_OTP,
                name: "Options-To-Purchase is generated"
            },
            {
                id: self.SALE_STATUS_DRAFT,
                name: "Draft"
            },
            {
                id: self.SALE_STATUS_CHEQUE_ENTERED,
                name: "Cheque is entered and status is on hold"
            },
            {
                id: self.SALE_STATUS_SPA_ENTERED,
                name: "SPA Date is entered"
            },
            {
                id: self.SALE_STATUS_EXTENDED,
                name: "Sale extended expiry date"
            },
            {
                id: self.SALE_STATUS_EXERCISED,
                name: "Sale exercised"
            },
            {
                id: self.SALE_STATUS_EXPIRED,
                name: "Sale expired"
            },
            {
                id: self.SALE_STATUS_ABORTED,
                name: "Sale aborted"
            },
            {
                id: self.SALE_STATUS_INVALID,
                name: "Invalid sale"
            },
            {
                id: self.SALE_STATUS_DONE,
                name: "Sale is completed"
            },
            {
                id: self.SALE_STATUS_DELETED,
                name: "Sale is deleted"
            }
        ];
    }

    getConfig() {
        const self = this;

        let url = 'config';

        loading(true);
        self.SSHttpService.getAPIRequest(url).then(function (response) {
            loading(false);
            if (response instanceof Error) {
                self.SSAlertService.parseAndDisplayError(response);
                return;
            }

            //get sale details
            self.sale_config = response.data;
            self.unit_statuses = response.data.unit_statuses;
        });
    }

    getProjectSettings(property_id){
        const self = this;

        if(!property_id){
            return;
        }

        let url = 'properties/' + property_id;
        let params = {
            include: 'settings',
        };

        loading(true);
        self.SSHttpService.getAPIRequest(url, params).then(function (response) {
            loading(false);
            if (response instanceof Error) {
                self.SSAlertService.parseAndDisplayError(response);
                return;
            }

            if(response.data && response.data.property && response.data.property.settings){
                
                let settings = response.data.property.settings;

                self.payment_type_setting = {
                    value: self.PAYMENT_PLAN_TYPE_ALL
                };
                self.payment_type_setting = response.data.property.settings.find(x => x.name === 'payment_type');

                //Get unit allocation settings
                if(settings){
                    try{
                        self.unit_allocation_settings = settings.find(x => x.name == 'unit_release_control');
                        if(self.unit_allocation_settings && parseInt(self.unit_allocation_settings.value) == 1){
                            // Get the Agencies of this property if 'unit_release_control' is on
                            self.getPropertyAgencies(property_id);
                            self.getPropertyPaymentPlan(property_id);
                        }
                    }
                    catch(ex){
                        console.log(ex);
                    }
                }
            }
        });
    }

    getPropertyAgencies(property_id){
        const self = this;

        if(!property_id){
            return;
        }

        let url = 'sec/developer/agencies';
        let params = {
            'property_id': property_id,
        };

        loading(true);
        self.SSHttpService.getAPIRequest(url, params).then(function (response) {
            loading(false);
            if (response instanceof Error) {
                self.SSAlertService.parseAndDisplayError(response);
                return;
            }

            if(response.data && response.data.property_agencies){
                self.property_agencies = response.data.property_agencies;
            }
        });
    }

    getUnitAllocationData(property_id){
        const self = this;

        let url = 'properties/' + property_id + '/release-control-data';
        let params = {
            'include': 'agencies'
        };

        loading(true);
        self.SSHttpService.getAPIRequest(url, params).then(function (response) {
            loading(false);

            if (response instanceof Error) {
                self.SSAlertService.parseAndDisplayError(response);
                return;
            }

            if(response.data && response.data.project_assigned_units){
              self.unitAllocationList = response.data.project_assigned_units;

              self.assigned_agencies = {};
              self.assigned_payment_plans = {};
              jQuery.each(self.unitAllocationList, function(index, unit){
                if(self.assigned_agencies.hasOwnProperty(unit.unit_id)){
                    unit.agency['time_stamp'] = self.getFormattedDateTime(unit);
                    unit.agency['remarks'] = unit.remarks ? unit.remarks : '--';
                    self.assigned_agencies[unit.unit_id].push(unit.agency);
                    unit.unit_detail = self.all_units.find(x => x.id == unit.unit_id);
                    unit.unit_detail.unit_status = self.unit_statuses.find(x => x.id === unit.unit_detail.status).name;
                }
                else{
                    self.assigned_agencies[unit.unit_id] = [];
                    unit.agency['time_stamp'] = self.getFormattedDateTime(unit);
                    unit.agency['remarks'] = unit.remarks ? unit.remarks : '--';
                    self.assigned_agencies[unit.unit_id].push(unit.agency);
                    unit.unit_detail = self.all_units.find(x => x.id == unit.unit_id);
                    unit.unit_detail.unit_status = self.unit_statuses.find(x => x.id === unit.unit_detail.status).name;
                }

                if (unit.payment_plan_id) {
                    let payment_plan_obj = {
                        payment_plan_id: unit.payment_plan_id,
                        payment_plan_lock: unit.lock_payment_plan
                    };

                    self.assigned_payment_plans[unit.unit_id] = payment_plan_obj;
                }
              });

              self.unitAllocationMasterList = angular.copy(self.unitAllocationList);

              self.unitAllocationList = self.unitAllocationList.filter(x => x.unit_detail.status == self.unit_allocation_filter_status);
            }
        });
    }

    canReleaseAllocation(unit){
        const self = this;

        if(!unit){
            return;
        }

        if(unit.status != self.UNIT_STATUS_RESERVED 
            && unit.status != self.UNIT_STATUS_SPECIAL_RESERVED 
            && unit.status != self.UNIT_STATUS_BOOKED
            && unit.status != self.UNIT_STATUS_SOLD){
            return true;
        }
        else{
            return false;
        }
    }

    toggleUnitAllocation(unit, allocate = true, agency_id = null){
        const self = this;

        self.unit_allocation_remarks_error = null;
        self.developer_agency_id_error = null;
        self.assigned_agency = null;
        self.assigned_unit = unit;
        self.assigned_allocate = allocate;
        self.payment_clause_error = null;

        if(self.developer_agency_id || agency_id){
            try{
                let _agency_id = agency_id ? agency_id : self.developer_agency_id;
                self.assigned_agency = self.property_agencies.find(x => x.agency_id == _agency_id);
            }
            catch(ex){
                console.log(ex);
            }
        }

        if (allocate) {
            if (!self.validateAllocationUnit()) {
                return;
            }
        }

        if (self.edit_percentage) {
            self.payment_clause_error = "Please save the customized payment clauses before proceed.";
            return false;
        }

        if (self.changed_percentage && self.payment_plan_id) {
            let payment_plan_clauses = {};
            let payment_plan_clauses_rules = {};
            $.each(self.display_payment_plan_obj.payment_plan_clauses, function(index, payment_plan_clause) {
                payment_plan_clauses[payment_plan_clause.id] = payment_plan_clause.amount_percentage;
                $.each(payment_plan_clause.rules, function(rules_index, rule) {
                    payment_plan_clauses_rules[rule.id] = {
                        'start_from': rule.start_from,
                        'billing_days': rule.billing_days
                    }
                });
            });

            let url = 'sec/developer/payment-plans/' + self.payment_plan_id + '/customize';
            let params = {
                'payment_plan_clauses': JSON.stringify(payment_plan_clauses),
                'payment_plan_clauses_rules': JSON.stringify(payment_plan_clauses_rules),
            };

            loading(true);
            self.SSHttpService.putAPIRequest(url, params).then(function (response) {
                loading(false);
                if (response instanceof Error) {
                    self.SSAlertService.parseAndDisplayError(response);
                    return;
                }

                //Add the newly created payment plan to the property
                let newly_added_payment_plan_id = angular.copy(response.data.payment_plan.id);
                let params = {
                    "payment_plan_id": newly_added_payment_plan_id,
                    "is_default": false
                };
                let url = 'sec/developer/properties/' + self.property.id + '/payment-plans';

                loading(true);
                self.SSHttpService.postAPIRequest(url, params).then(function (response) {
                    loading(false);
                    if (response instanceof Error) {
                        self.SSAlertService.parseAndDisplayError(response);
                        return;
                    }

                    //Update payment plan selector
                    self.getPropertyPaymentPlan(self.property.id);
                    self.callAssignUnitAPI(newly_added_payment_plan_id);
                });


            });
        } else {
            self.callAssignUnitAPI();
        }
    }

    callAssignUnitAPI(payment_plan_id) {
        const self = this;

        let url = 'sec/developer/properties/' + self.property.id + '/units/' + self.assigned_unit.id + '/release-control';
        let params = {
            'agency_project_id': self.assigned_agency.id,
            'status': self.assigned_allocate ? 1 : 0,
            'for_reissue': self.for_reissue ? 1 : 0,
            'remarks': self.unit_allocation_remarks,
        };

        if (self.payment_plan_id) {
            params["payment_plan_id"] = payment_plan_id ? payment_plan_id : self.payment_plan_id;
            params["lock_payment_plan"] = self.payment_plan_lock ? 1 : 0;
        }

        loading(true);
        self.SSHttpService.postAPIRequest(url, params).then(function (response) {
            loading(false);
            if (response instanceof Error) {
                self.SSAlertService.parseAndDisplayError(response);
                return;
            }
            // let message = (allocate ? 'Agency is allocated successfully.' : 'Agency removed successfully.')
            let message = response.msg ? response.msg : 'Unit allocation updated successfully'
            self.SSAlertService.success('Success!', message);
            $('#authCode').modal('hide');
            // Reload updated data
            self.getUnitAllocationData(self.property.id);
        });
    }

    toggleView(type){
        const self = this;

        self.apiOffset = 0;

        self.stackViewType = type;
        self.viewType = type;

        self.processServerData(self.stacks);
    }

    getFormattedDateTime(unit){
        const self = this;

        if(!unit){
            return '--';
        }

        try{
            if(unit.updated_at){
                var date = unit.updated_at;
            }
            else if(unit.created_at){
                var date = unit.created_at;
            }
            else{
                return '--';
            }

            return self.SSUtilService.formatUtcToLocalDate(date) + " " + self.SSUtilService.formatUtcToLocalTime(date);
        }
        catch(ex){
            console.log(ex);
        }
    }

    getPropertyList() {
        const self = this;

        self.properties = [];
        let url = 'sec/developer/properties/list';

        loading(true);
        self.SSHttpService.getAPIRequest(url).then(function (response) {
            loading(false);

            if (response instanceof Error) {
                self.SSAlertService.parseAndDisplayError(response);
                return;
            }

            // WEB-2197 -- Do not show projects which are on "Archive" in the project list
            if(response.data && response.data.properties){
                $.each(response.data.properties, function(index, property){
                    if(property.status != self.PROPERTY_STATUS_ARCHIVED){
                        self.properties.push(property);
                    }
                });
            }

            self.$timeout(function() { $("select.property-select").niceSelect('update'); });

            // WEB-877 -- DMS/LIVE STACK VIEW: Do not show any default project
            // if(self.data.property_id){
            //     self.property_selected = self.data.property_id;
            //     self.getProperty(self.data.property_id);
            // }
            // else {
            //     if (self.propertyList.properties.length > 0) {
            //         self.property_selected = self.propertyList.properties[0].named_id;
            //         self.getProperty(self.property_selected);
            //     }
            // }
        });
    }


    getProperty(propertyId) {
        const self = this;

        self.data.property_id = propertyId;
        if (!self.data.property_id)
            return;

        self.getProjectSettings(propertyId);

        self.property = null;
        let url = 'sec/developer/properties/' + self.data.property_id;

        loading(true);
        self.SSHttpService.getAPIRequest(url).then(function (response) {
            loading(false);

            if (response instanceof Error) {
                self.SSAlertService.parseAndDisplayError(response);
                return;
            }

            self.property = response.data.property;
            self.stats = response.data.stats;
            self.typeMaps = response.data.type_map;
            self.unitTypes = response.data.unit_types;
            self.selectedStackId = null;
            self.getUnits();
            self.$timeout(function() { $("select.property-select").niceSelect('update'); });
        });
    }


    getUnits(show_loading = true) {
        const self = this;

        if (!self.property.id){
            return;
        }

        let url = 'properties/'+ self.property.id +'/stacks';
        
        let params = {
            'response_md5': self.response_md5,
            'return_property' : true,
            'property_columns' : 'id,named_id,name,type,product_type,status,location,country,region',
            'return_stack' : true,
            'stack_columns' : 'id,named_id,name',
            'include_units' : true,
            'include_unit_assets' : true,
            'unit_columns' : 'id,named_id,floor_no,unit_no,house_no,category,type,name,baths,status,price,start_price,end_price,size_ft2,psf,floor_plan_3d'
        };

        if(show_loading){
            loading(true);
        }

        self.SSHttpService.getAPIRequest(url,params).then(function (response) {
            loading(false);
            
            // Auto refresh
            self.$timeout(function() { 
              self.getUnits(false);
            }, self.AUTO_REFRESH_TIME? self.AUTO_REFRESH_TIME : 10*1000);

            if (response instanceof Error) {
                self.SSAlertService.parseAndDisplayError(response);
                return;
            }

            if(response.sub_code == 204001){
                return;
            }

            self.response_md5 = response.data.response_md5;
            self.units = response.data.property_stacks;
            self.getPropertyStacks(show_loading);
            // Get unit allocation data to check which agencies are assigned to which units
            self.getUnitAllocationData(self.property.id);
            
        });
    }

    getPropertyStacks() {
        const self = this;

        self.sold_count = '0';

        let _STACKS =  self.units;

        self.checkListViewOnly();
        self.processServerData(_STACKS);
        self.getPropertyUnitTypes();

        self.isEoiAccessible = !!self.SSUserService.getActivePropertyLaunchForEoiCreate(self.property, 'EOI');
    }

    checkListViewOnly() {
        const self = this;

        let stack_view_visibility = self.SSAppService.getPropertySetting(self.property, 'stack_view_visibility');

        if (stack_view_visibility !== null && typeof stack_view_visibility === 'object' && stack_view_visibility.hasOwnProperty("value") && stack_view_visibility.value != null) {
            stack_view_visibility = stack_view_visibility.value;
        }

        if (stack_view_visibility == SHOWSUITE.AVAILABILITY_BROKERS_TEAM) {
            if (!self.SSUserService.isBroker()) {
                self.viewType = self.stackViewType;
                self.disable_stackview = true;
                return;
            }
            else {
                loading(true);
                self.SSUserService.getBrokerAgencyWithProperty(property).then(function (response) {
                    loading(false);
                    if (response instanceof Error) {
                        self.SSAlertService.parseAndDisplayError(response);
                        return;
                    }
                    if (!(response.data && response.data.properties)) {
                        self.viewType = self.stackViewType;
                        self.disable_stackview = true;
                        return;
                    }

                    let properties = response.data.properties;

                    $.each(properties, function (index, _property) {
                        if (_property.id === self.property.id) {
                            self.viewType = self.stackViewType;
                            return;
                        }
                    });

                    if (self.viewType !== 1) {
                        // self.viewType = 1;
                        self.disable_stackview = true;
                        return;
                    }
                });
            }
        } else if (stack_view_visibility == SHOWSUITE.AVAILABILITY_BROKERS_ALL) {
            if (self.SSUserService.isBroker()) {
                self.viewType = self.stackViewType;
            } else {
                self.viewType = self.stackViewType;
                self.disable_stackview = true;
                return;
            }
        } else if (stack_view_visibility == SHOWSUITE.AVAILABILITY_PUBLIC) {
            self.viewType = self.stackViewType;
        } else {
            self.viewType = self.stackViewType;
            self.disable_stackview = true;
        }
    }

    processServerData(_STACKS) {
        const self = this;

        self.units_view_master = [];

        if(!_STACKS){
            self.stacks = [];
            return;
        }

        self.all_units = [];

        // If property changed then update stacks
        if((self.stacks.length == 0 || (self.viewType == 2) || 
            (self.stacks.length > 0 && _STACKS.length > 0 && self.stacks[0].named_id != _STACKS[0].named_id)) || self.viewType == 3){
            self.stacks = _STACKS;
            jQuery.each(self.stacks, function (i, stack) {
                stack.floorsData = self.SSUtilService.getFloorWiseUnits(stack.units);
                stack.availableUnits = self.filterAvailableUnits(stack.units);
                $.merge(self.all_units, stack.units);
                if(stack.availableUnits && stack.availableUnits.length > 0){
                    self.availableUnits = stack.availableUnits;
                }
            });
            console.log('self.all_units');
            console.log(self.all_units);
        }
        else{
            // Find the updated data from stacks array and replace in old stacks array
            jQuery.each(self.stacks, function (i, old_stack) {
                let new_stack = _STACKS[i];

                if(!new_stack){
                    return false;
                }

                if(!_.isEqual(old_stack, new_stack)){
                    let newFloorsData = self.SSUtilService.getFloorWiseUnits(new_stack.units);
                    let newAvailableUnits = self.filterAvailableUnits(new_stack.units);

                    // Update floors data if changed
                    if(!_.isEqual(old_stack.floorsData, newFloorsData)){
                        $.each(old_stack.floorsData, function(f, floor){
                            if(!_.isEqual(floor, newFloorsData[f])){
                                floor = newFloorsData[f];
                                // Update unit class
                                $.each(floor, function(_f, f_unit){
                                    self.updateUnitClass(f_unit);
                                });
                            }
                        });
                    }

                    // Update units data if changed
                    if(!_.isEqual(old_stack.units, new_stack.units)){
                        $.each(old_stack.units, function(u, _unit){
                            if(!_.isEqual(_unit, new_stack.units[u])){
                                _unit = new_stack.units[u];
                                self.updateUnitClass(_unit);
                            }
                        });
                    }

                    // Update available units
                    // availableUnits can increase (if sale aborted) or decrease (if unit sold out)
                    if(!_.isEqual(old_stack.availableUnits, newAvailableUnits)){
                        if(old_stack.availableUnits.length < newAvailableUnits.length){
                            // Need to push the new available unit to old stack
                            $.each(newAvailableUnits, function(u, new_unit){
                                let _unit = old_stack.availableUnits.find(x => x.id == new_unit.id);
                                if(!_unit){
                                    self.updateUnitClass(_unit);
                                    old_stack.availableUnits.push(new_unit);
                                }
                            });
                        }
                        else if(old_stack.availableUnits.length > newAvailableUnits.length){
                            // Need to remove updated unit from old stack
                            $.each(old_stack.availableUnits, function(index, old_unit){
                                // If old unit not found in new available units then it is removed when data updated
                                let _unit = newAvailableUnits.find(x => x.id == old_unit.id);
                                self.updateUnitClass(_unit);
                                if(!_unit){
                                    // Remove 1 item/object from given position/index
                                    old_stack.availableUnits.splice(index, 1);
                                } 
                            });
                        }
                    }
                }

                $.merge(self.all_units, new_stack.units);
            });
        }

        self.loadUnitsView();

        if(self.stacks && self.stacks.length > 0){
            self.selectedStackId = self.selectedStackId? self.selectedStackId : self.stacks[0].id;
        }

        self.SSAppService.isPageLoaded = true;
    }

    getPropertyUnitTypes() {
        const self = this;

        let _propertyUnitCategories = [];
        let total_units = 0;
        let sold_units = 0;

        jQuery.each(self.stacks, function (i, stack) {
            jQuery.each(stack.units, function (j, unit) {
                let category = null;
                if (unit.category in self.UNIT_CATEGORIES_MAP) {
                    category = self.UNIT_CATEGORIES_MAP[unit.category];
                } else {
                    category = self.UNIT_CATEGORIES_MAP[0];
                }

                if (_propertyUnitCategories.indexOf(category) == -1) {
                    _propertyUnitCategories.push(category);
                }

                total_units += 1;
                if (unit.status == self.UNIT_STATUS_SOLD) {
                    sold_units += 1;
                }
            });
        });

        self.total_units = total_units;
        self.sold_count = sold_units;
        self.propertyUnitCategories = _propertyUnitCategories;
    }

    filterAvailableUnits(units) {
        const self = this;

        let statuses = [self.UNIT_STATUS_LISTED, self.UNIT_STATUS_RESERVED];
        let filtered = [];

        _.each(units, function (unit) {
            unit.cls = self.getUnitCellClass(unit);
            if (statuses.indexOf(unit.status) !== -1)
                filtered.push(unit);
        });

        if (self.sortBy) {
            filtered = _.sortBy(filtered, function (item) {
                return item[self.sortBy];
            })
        }

        return filtered;
    }

    loadUnitsView(){
        const self = this;

        let _units_view = [];

        if(!self.units_view_master.length){
            self.units_view_master = self.availableUnits || [];
        }

        self.count = self.units_view_master.length;
        self.pages = Math.ceil(self.count / self.apiLimit);
        self.selectedPage = Math.floor(self.apiOffset / self.apiLimit) + 1;
        self.pagesArr = [];

        for (var i = 0; i < self.pages; i++) {
            self.pagesArr.push(i);
        }

        for(var i = 0; i < self.apiLimit; i++){
            if(self.apiOffset+i < self.count){
                _units_view.push(self.units_view_master[self.apiOffset + i]);
            }
        }

        self.units_view = _units_view;
    }

    updateUnitClass(unit){
        const self = this;

        if($('#'+unit.id).hasClass("reserved")) {
            $('#'+unit.id).removeClass("reserved");
        }
        if($('#'+unit.id).hasClass("sold")) {
            $('#'+unit.id).removeClass("sold");
        }
        
        $('#'+unit.id).addClass(self.getUnitCellClass(unit));
    }

    pagesGoBack() {
        const self = this;

        self.selectedPage--;
        self.apiOffset = self.apiOffset - self.apiLimit;
        self.loadUnitsView();
    }

    pagesGoNext() {
        const self = this;

        self.selectedPage++;
        self.apiOffset = self.apiOffset + self.apiLimit;
        self.loadUnitsView();
    }
    getUnitPrice(unit) {
        return unit.price;
    }

    gotoPage(pageNum){
        const self = this;

        self.apiOffset = parseInt(pageNum) * self.apiLimit;
        self.loadUnitsView();
    }

    sortData(sortBy) {
        const self = this;

        self.sortBy = sortBy;

        switch (self.sortBy) {
            case "unit_no":
                self.sortByHeading = "#UNIT NO";
                break;
            case "house_no":
                self.sortByHeading = "HOUSE NO";
                break;
            case "name":
                self.sortByHeading = "TYPE";
                break;
            case "price":
                self.sortByHeading = "$PRICE";
                break;
            default:
                self.sortByHeading = "Sort by...";
                break;
        }

        self.processServerData(self.stacks);
    }

    getUnitCellClass(unit) {
        const self = this;

        let cls = '';

        if (!unit.category) {
            cls = 'empty';
        } else if (unit.status == self.UNIT_STATUS_LISTED) {
            if (unit.category in self.UNIT_CATEGORIES_MAP) {
                cls = self.UNIT_CATEGORIES_MAP[unit.category].className;
            } else {
                cls = self.UNIT_CATEGORIES_MAP[0].className;
            }
        } else if (unit.status == self.UNIT_STATUS_SOLD) {
            cls = 'sold';
        } else if (unit.status == self.UNIT_STATUS_DRAFT) {
            cls = 'draft';
        } else {
            cls = 'reserved';
        }

        return cls;
    }

    viewUnit(unit) {
        const self = this;
        
        self.developer_agency_id = null;
        self.unitSelected = null;
        self.brokerDetails = null;
        self.buyerDetails = null;
        self.unitSelected = unit;
        self.unit_allocation_remarks_error = null;
        self.payment_plan_id = null;
        self.payment_plan_lock = null;
        self.changed_percentage = false;
        self.edit_percentage = false;

        let url = 'sec/sales/unit-sale-status';
        let params = {
            'unitId' : unit.id,
            'include' : 'sale_buyers,brokers,agencies,sale_statuses'
        };

        loading(true);
        self.SSHttpService.getAPIRequest(url,params).then(function (response) {
            loading(false);

            if (response instanceof Error) {
                self.SSAlertService.parseAndDisplayError(response);
                return;
            }

            self.unitDetails = response.data;
            self.unit_status = self.unit_statuses.find(x => x.id === self.unitDetails.unit.status).name;

            let found_status = self.sale_statuses.find(x => x.id === unit.sale_status);

            if (found_status) {
                if (unit.reissued_from_sale_id) {
                    self.sale_status = "Reissue: " + found_status.name;
                } else {
                    self.sale_status = found_status.name;
                }
            } else {
                self.sale_status = "N/A";
            }
            
            if (self.assigned_payment_plans[unit.id]) {
                self.payment_plan_id = self.assigned_payment_plans[unit.id].payment_plan_id;
                self.payment_plan_lock = self.assigned_payment_plans[unit.id].payment_plan_lock ? true : false;
            }

            if(self.unitDetails.sale_details)
            {
                jQuery.each(self.unitDetails.sale_details.sales, function (i, sale) {
                    jQuery.each(sale.brokers, function (i, broker) {
                        if (broker.broker_role == 1) {
                            self.brokerDetails = self.unitDetails.sale_details.brokers.find(x => x.user_id === broker.broker_user_id).user;
                            return false;
                        }
                    });
                });

                jQuery.each(self.unitDetails.sale_details.sales, function (i, sale) {
                    jQuery.each(sale.sale_buyers, function (i, buyer) {
                        self.buyerDetails = JSON.parse(buyer.data);
                        return false;
                    });
                });
            }

            //Check has payment plan on that unit or not
            if (self.assigned_payment_plans[unit.id]) {
                self.unit_payment_plan_obj = self.developer_payment_plans.find(x => x.id == self.assigned_payment_plans[unit.id].payment_plan_id);
                if (self.unit_payment_plan_obj) {
                    self.display_payment_plan_obj = self.payment_plans.find(x => x.id == self.unit_payment_plan_obj.id);
                }
            }

            $('#authCode').modal('show');
        });
    }



    abortUnit(unit) {
        const self = this;

        self.OTPService.getVerified(self.OTPService.TYPE_SESSION_2FA, function(data){

            if(!self.unitDetails.unit.id)
                return;

            self.SSConfirmationService.getConfirmed(
                'Confirm!',
                'Are you sure you want to abort this unit?',
                self.SSConfirmationService.TYPE_DEFAULT,
                function() {

                    //let url = 'sec/developer/units/' + self.unitDetails.unit.property_id + '/json-update';
                    let url = 'sec/developer/units/' + self.unitDetails.unit.id + '/unit-abort';
                    let params = {
                        'propertyId': self.unitDetails.unit.property_id,
                        //'units_data': {units : [ {
                        //        "id" : self.unitDetails.unit.id,
                        //        "status": self.UNIT_STATUS_LISTED,
                        //        // "floor_no": self.unitDetails.unit.floor_no,
                        //        // "unit_no": self.unitDetails.unit.unit_no,
                        //        // "type": self.unitDetails.unit.type,
                        //        // "category": self.unitDetails.unit.category,
                        //        // "price": self.unitDetails.unit.price,
                        //        // "floorplan_id": self.unitDetails.unit.floor_plan_id,
                        //        // "price_type": self.unitDetails.unit.price_type,
                        //        // "name": self.unitDetails.unit.name
                        //}]},
                    };

                    loading(true);
                    self.SSHttpService.postAPIRequest(url, params).then(function (response) {
                        loading(false);
                        if (response instanceof Error) {
                            self.SSAlertService.parseAndDisplayError(response);
                            return;
                        }

                        // Update sale status
                        self.unitDetails.unit.status = self.UNIT_STATUS_LISTED;
                        unit.status = self.UNIT_STATUS_LISTED;
                       // self.unitSelected.status = self.UNIT_STATUS_LISTED;
                        $('#authCode').modal('hide');
                        self.SSAlertService.success('Unit is aborted successfully.');
                    });

                }
            );
        });
    }

    filterViewStack(className) {
        const self = this;

        self.selected_class_name = className;
        var buttons = $( "div.scroll" ).find( "button" );

        //Make all visible
        var invisible_buttons = $( "div.scroll" ).find( "button.greyout" );
        jQuery.each(invisible_buttons, function (i, button) {
            $(button).removeClass("greyout");
        });

        //Make all invisible other than className
        if(className) {
            jQuery.each(buttons, function (i, button) {
                if(!$(button).hasClass(className)) {
                    $(button).addClass("greyout");
                }
            });
        }
    }

    get360photoClass(unit) {
        const self = this;

        if (self.checkUnitAssetClass(unit, self.FLOORPLAN_ASSET_TYPE_360_IMAGE)) {
            return '360photo';
        }
    }

    get3DFloorPlanClass(unit) {
        if (unit.floor_plan_3d != null && unit.floor_plan_3d != "" && unit.floor_plan_3d != undefined) {
            return '3Dfloorplan';
        }
    }

    checkUnitAssetClass(unit, floorPlanType) {
        const self = this;

        if (unit.hasOwnProperty("unit_assets") && unit.unit_assets.length > 0 && unit.unit_assets.find(x => x.type == floorPlanType)) {
            return true;
        }
    }

    getPropertyPaymentPlan(property_id) {
        const self = this;

        let url = 'sec/developer/properties/' + property_id + '/payment-plans';

        let params = {
            include: 'payment_plan_clauses'
        };

        loading(true);
        self.SSHttpService.getAPIRequest(url, params).then(function (response) {
            loading(false);
            if (response instanceof Error) {
                self.SSAlertService.parseAndDisplayError(response);
                return;
            }

            if(response.data && response.data.payment_plans){
                if (self.payment_type_setting.value == self.PAYMENT_PLAN_TYPE_ALL) {
                    self.payment_plans = response.data.payment_plans;
                } else {
                    self.payment_plans = response.data.payment_plans.filter(x => x.type == self.payment_type_setting.value);
                }
            }
        });
    }

    validateAllocationUnit() {
        const self = this;

        let validate = true;

        if (self.unit_allocation_remarks && self.unit_allocation_remarks.toString().length > 255) {
            self.unit_allocation_remarks_error = "The remarks character is greater than 255.";
            validate = false;
        }

        if (!self.developer_agency_id) {
            self.developer_agency_id_error = "Please select Agency.";
            validate = false;
        }

        return validate;
    }

    checkAssignedPaymentPlan(unit) {
        const self = this;

        self.show_payment_plan_obj = {};

        if (unit) {
            return true;
        }

        return false;
    }

    getDeveloperPaymentPlans() {
        const self = this;

        let url = "sec/developer/payment-plans";

        loading(true);
        self.SSHttpService.getAPIRequest(url).then(function (response) {
            loading(false);
            if (response instanceof Error) {
                self.SSAlertService.parseAndDisplayError(response);
                return;
            }

            self.developer_payment_plans = response.data;
        });
    }

    changePaymentPlan() {
        const self = this;

        self.cancelPaymentClausePercentage();
        self.edit_percentage = false;

        if (self.payment_plan_id) {
            self.display_payment_plan_obj = self.payment_plans.find(x => x.id == self.payment_plan_id);
        } else {
            self.display_payment_plan_obj = null;
        }
    }

    editPaymentClausePercentage() {
        const self = this;

        self.temp_payment_plan_clauses = angular.copy(self.display_payment_plan_obj.payment_plan_clauses);
        self.edit_percentage = !self.edit_percentage;
    }

    getPaymentTypeName(type) {
        const self = this;

        let name = '--';

        if(!type) {
          return name;
        }

        try {
            let payment_type = self.PAYMENT_TYPES.find(x => x.id == type);
            if(payment_type){
                name = payment_type.name;
            }
        }
        catch(ex){
            console.log(ex);
        }

        return name;
    }

    getAvailablePayments() {
        const self = this;

        return self.data.config.payment_types.filter(x => x.id != self.PAYMENT_TYPE_20_REFUND);
    }

    savePaymentClausePercentage() {
        const self = this;

        let total_percentage = 0;
        let rule_error = false;
        self.payment_clause_error = "";

        $.each(self.display_payment_plan_obj.payment_plan_clauses, function(index, payment_plan_clause) {
            total_percentage += parseFloat(payment_plan_clause.amount_percentage);
            //Check payment clause rules
            $.each(payment_plan_clause.rules, function(rules_index, rule) {
                if (!rule.billing_days) {
                    rule_error = true;
                    return false;
                }
            });
        });

        if (total_percentage != 100) {
            self.payment_clause_error = "Total of all payment plan clauses percentage must be 100%.";
            return;
        }

        if (rule_error) {
            self.payment_clause_error = "Number of days in rules cannot be empty.";
            return;
        }

        self.changed_percentage = true;
        self.edit_percentage = !self.edit_percentage;
    }

    cancelPaymentClausePercentage() {
        const self = this;

        self.payment_clause_error = "";
        if (self.temp_payment_plan_clauses) {
            self.display_payment_plan_obj.payment_plan_clauses = angular.copy(self.temp_payment_plan_clauses);
        }
        self.edit_percentage = !self.edit_percentage;
    }

    openSalesStatistics(propertyId) {
        let self = this;
        self.SSUtilService.openUrlInNewTab('/developer/properties/' + propertyId + '/sales-stats');
    }
    openStacksView(propertyId) {
        let self = this;
        self.SSUtilService.openUrlInNewTab('/developer/properties/' + propertyId + '/stackview/detail');
    }

    updateUnitAllocationList() {
        const self = this;

        self.unitAllocationList = angular.copy(self.unitAllocationMasterList);

        self.unitAllocationList = self.unitAllocationList.filter(x => x.unit_detail.status == self.unit_allocation_filter_status);
    }

}

DeveloperSalesUnitsController.$inject = ['$scope', '$localStorage', '$timeout', 'SSHttpService', 'SSUtilService', 'SSConfirmationService', 'SSAppService', 'SSAlertService', 'SSUserService', 'OTPService', 'SSConfigService', 'SSAnalyticsService', 'SSStackviewService'];
app.controller('DeveloperSalesUnitsController', DeveloperSalesUnitsController);
